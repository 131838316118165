@import '../../Styles/variables';

.homeView {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.homeSegment {
  background: transparent !important;
}

.container {
  padding: 0 !important;

  @media(min-width: $tablet) {
    padding: 0 1rem !important;
  }

  @media(min-width: $desktop) {
    padding: 0 2rem !important;
  }
}

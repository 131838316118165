// sizes
$desktop: 1300px;
$tablet: 768px;

// colors
$white: #fff;
$xlight-gray: #f7f7f7;
$light-gray: #e0e0e0;
$white30: rgba(255, 255, 255, .3);
$white80: rgba(255, 255, 255, .8);
$white86: rgba(255, 255, 255, .86);
$white90: rgba(255, 255, 255, .9);
$black: #000;
$black80: rgba(0, 0, 0, .8);
$black50: rgba(0, 0, 0, .5);
$black40: rgba(0, 0, 0, .4);
$black30: rgba(0, 0, 0, .3);
$darkgray: #333;

$primary: #f00;
$primary-dark: #9b0000;
$primary-light: #ff3939;
$primary-extra-light: #ff6464;

$secondary: #0c0;
$secondary-dark: #007c00;
$secondary-light: #55d955;

$accent: #ff7400;
$accent-dark: #9b4600;
$accent-light: #ff9339;
$accent-extra-light: #ffaa64;

$flat: #099;
$flat-dark: #005d5d;
$flat-light: #24a0a0;
$flat-extra-light: #46b3b3;



// navbar
$navbar-bg: $black;

// footer
$footer-bg: transparent;
$footer-fg: #000;

// widget
$widget-bg: $xlight-gray;

.networkErrorView {
  align-items: center;
  background: #fff url('../../Assets/img/bg.jpg') no-repeat center center !important;
  background-size: cover !important;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.content {
  background: #fff;
  padding: 3rem;
}

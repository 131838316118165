@import './variables';

%widget {
  align-items: center;
  background: $widget-bg;
  border-radius: 1.5rem;
  box-shadow: 0 0 2rem rgba(0, 0, 0, .2);
  display: flex;
  flex-direction: column;
  margin: 0 0 2rem;
  padding: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

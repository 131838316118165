/* app wide styles */
#root {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg width='6' height='6' viewBox='0 0 6 6' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='%23d2cbdd' fill-opacity='0.31' fill-rule='evenodd'%3E%3Cpath d='M5 0h1L0 6V5zM6 5v1H5z'/%3E%3C/g%3E%3C/svg%3E");
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.app {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.prettyprint {
  overflow: auto;
}

.ui.basic.table tbody tr {
  border-bottom: 0px solid !important;
  box-shadow: 0 0 0 transparent !important;
}

.reactour__helper {
  background: #FBE364 !important;
  border-radius: 1.5rem !important;
  max-width: 500px !important;
  width: 95% !important;
}

.sidebar-menu .corner i {
  cursor: pointer !important;
}

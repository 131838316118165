@import '../../Styles/variables';

.loginForm {
  background-color: $darkgray;
  border-radius: 1.5rem;
  box-shadow: 0 0 3rem $black;
  max-width: 90%;
  width: 400px;
}

.logoContainer {
  border-radius: 1.5rem 1.5rem 0 0;
  padding: 1rem;
  text-align: center;

  img {
    width: 200px;
  }
}

.discalimer {
  font-style: italic;
}

.form {
  background: $white;
  border-radius: 0 0 1.5rem 1.5rem;
  margin-top: 0;
  padding: 2rem 1rem 1rem;
}

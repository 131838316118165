@import '../../Styles/placeholders';

.widget {
  @extend %widget;
}

.icon {
  color: $accent-light !important;
}

.statistic {
  margin: 1rem 2rem !important;
}
